import type { TerritorialUnit, TerritorialUnitType } from './types';

export const TERRITORIAL_UNITS_REQUESTED = 'TERRITORIAL_UNITS_REQUESTED' as const;
export const requestTerritorialUnits = (type: TerritorialUnitType, parentId: string) => ({
  payload: { type, parentId },
  type: TERRITORIAL_UNITS_REQUESTED,
});

export const TERRITORIAL_UNITS_RECEIVED = 'TERRITORIAL_UNITS_RECEIVED' as const;
export const territorialUnitsReceived = (territorialUnits: Array<TerritorialUnit>) => ({
  type: TERRITORIAL_UNITS_RECEIVED,
  payload: territorialUnits,
});

type TerritorialUnitAction = ReturnType<
  | typeof requestTerritorialUnits
  | typeof territorialUnitsReceived
>;

export type EntityTemplateFieldsAction = TerritorialUnitAction;
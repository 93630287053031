import type { Epic } from 'behavior/types';
import { EntityTemplateFieldsAction, territorialUnitsReceived } from './actions';
import { merge, of } from 'rxjs';
import {
  switchMap,
  pluck,
  mergeMap,
  startWith,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { TERRITORIAL_UNITS_REQUESTED } from './actions';
import { requestTerritorialUnitsQuery } from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { TerritorialUnit } from './types';
type TerritorialUnitsResponse = {
  territorialUnits: TerritorialUnit[];
};

const entityTemplateFieldsEpic: Epic<EntityTemplateFieldsAction> = (action$, state$, { api, logger }) => {
  const territorialUnits$ = action$.pipe(
    ofType(TERRITORIAL_UNITS_REQUESTED),
    pluck('payload'),
    mergeMap(({ type, parentId }) =>
      api.graphApi<TerritorialUnitsResponse>(requestTerritorialUnitsQuery, { type, parentId }).pipe(
        mergeMap(({ territorialUnits }) => [territorialUnitsReceived(territorialUnits), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  return merge(
    territorialUnits$,
  );
};

export default entityTemplateFieldsEpic;
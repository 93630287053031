type CallbackAction = { type: string; payload?: any };

export const modifyPageTitle = (pageTitle: { title: string | null } | { showTitle: boolean }) => ({
  type: 'VD/MODIFY_PAGE_TITLE' as const,
  payload: { pageTitle },
});

export const moveContentElement = (elementData: { indexBefore: number; indexAfter: number } | { indexBefore: number; indexAfter: number; sourceElementId: string; targetElementId: string }) => ({
  type: 'VD/MOVE_CONTENT_ELEMENT' as const,
  payload: elementData,
});

export const requestVisualDesignerDelete = (description: string, callbackAction: CallbackAction, frontendCallbackAction: CallbackAction) => ({
  type: 'VD/DELETE_REQUESTED' as const,
  payload: { description, callbackAction, frontendCallbackAction },
});

export const updateHeight = (height: number) => ({
  type: 'VD/WEBSTORE/UPDATE_HEIGHT' as const,
  payload: { height },
});

export const VISUAL_DESIGNER_LOADED = 'VD/LOADED' as const;
export const visualDesignerLoaded = (mode: string) => ({
  type: VISUAL_DESIGNER_LOADED,
  payload: { mode },
});

export const visualDesignerReady = () => ({
  type: 'VD/WEBSTORE/READY',
});

export const VISUAL_DESIGNER_LANGUAGE_CHANGED = 'VD/LANGUAGE_CHANGED' as const;
export const VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED = 'VD/RESOURCE_TEXTS_RECEIVED' as const;
export const VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES = 'VD/SET_CUSTOMER_SEGMENT_TITLES' as const;
export const VISUAL_DESIGNER_SET_PAGE_ERRORS = 'VD/SET_PAGE_ERRORS' as const;
export const VISUAL_DESIGNER_SET_SETTINGS = 'VD/SET_SETTINGS' as const;

export type ContentBlocksAction = ReturnType<
  | typeof modifyPageTitle
  | typeof moveContentElement
  | typeof requestVisualDesignerDelete
  | typeof updateHeight
  | typeof visualDesignerLoaded
  | typeof visualDesignerReady
>;

import { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSliderControl, Slider } from 'components/primitives/slider';
import { FullScreenPopup } from 'components/objects/fullScreenPopup';
import { ProductMediaType } from 'behavior/pages/product';
import VideoSlide from './VideoSlide';
import ImageSlide from './ImageSlide';

const SliderPopup = ({ hide, items, index = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(index);

  const onSlideChange = useCallback(index => setActiveIndex(index), []);

  const slides = useMemo(() => items.map((item, i) => {
    const Slide = item.type === ProductMediaType.Image ? ImageSlide : VideoSlide;

    return <Slide item={item} index={i} active={activeIndex === i} />;
  }), [items, activeIndex]);

  const control = useSliderControl(index);
  const { prev, next } = control;
  const slidesLen = slides.length;

  const handleKeyDown = useCallback(e => {
    if (slidesLen <= 1)
      return;

    if (e.key === 'ArrowLeft') {
      prev();
      return;
    }

    if (e.key === 'ArrowRight') {
      next();
      return;
    }
  }, [slidesLen]);

  return (
    <FullScreenPopup
      hide={hide}
      onKeyDown={handleKeyDown}
      aria-keyshortcuts={slidesLen > 1 ? 'ArrowRight ArrowLeft' : null}
    >
      {slidesLen > 1
        ? <Slider items={slides} control={control} onSlideChange={onSlideChange} noMouseSliding />
        : slides[0]
      }
    </FullScreenPopup>);
};

SliderPopup.propTypes = {
  hide: PropTypes.func.isRequired,
  items: PropTypes.array,
  index: PropTypes.number,
};

export default SliderPopup;
import { isEqual } from 'lodash';
import { visualDesignerHeader, insiteEditorHeader } from './constants';

export function modifyContentElementById(id, content, modifyAction) {
  const { row: oldRow, column: oldColumn, contentBlock: oldBlock } = findContentElementById(id, content);
  if (!oldRow)
    return content;
  if (id === oldRow.id) {
    return replaceById(id, content, modifyAction(oldRow));
  }
  if (id === oldColumn.id) {
    const newRow = {
      ...oldRow,
      columns: replaceById(id, oldRow.columns, modifyAction(oldColumn)),
    };
    return replaceById(oldRow.id, content, newRow);
  }
  if (id === oldBlock.id) {
    const newColumn = {
      ...oldColumn,
      contentBlocks: replaceById(id, oldColumn.contentBlocks, modifyAction(oldBlock)),
    };
    const newRow = {
      ...oldRow,
      columns: replaceById(oldColumn.id, oldRow.columns, newColumn),
    };
    return replaceById(oldRow.id, content, newRow);
  }
}

function replaceById(id, array, newItem) {
  return array.map(item => item.id === id ? newItem : item);
}

export function findContentElementById(id, content) {
  function find(array, func) {
    for (let i = 0; i < array.length; i++) {
      const result = func(array[i], i);
      if (result)
        return result;
    }
    return null;
  }

  const result = find(content, (row, rowIndex) => {
    if (row.id === id) {
      return { row, rowIndex };
    }
    return find(row.columns, (column, columnIndex) => {
      if (column.id === id) {
        return { row, rowIndex, column, columnIndex };
      }

      return find(column.contentBlocks, (contentBlock, contentBlockIndex) => {
        if (contentBlock.id === id) {
          return { row, rowIndex, column, columnIndex, contentBlock, contentBlockIndex };
        }

        return null;
      });
    });
  });

  return result || {};
}

export function mergePageContentData(oldData, newData) {
  if (oldData) {
    newData = newData.map(rowData => {
      rowData.columns = rowData.columns.map(columnData => {
        columnData.contentBlocks = columnData.contentBlocks.map(contentBlockData => {
          const oldContentBlockDataModel = findContentElementById(contentBlockData.id, oldData)?.contentBlock?.model;
          if (isEqual(oldContentBlockDataModel, contentBlockData.model))
            contentBlockData.model = oldContentBlockDataModel;

          return contentBlockData;
        });
        return columnData;
      });
      return rowData;
    });
  }
  return newData;
}

export function isVisualDesignerLocation(location) {
  return location.pathname === '/sana-visual-designer';
}

export function getVisualDesignerMode(location) {
  const match = location.search.match(/[?&]mode=([a-zA-Z]+)/);
  return match[1];
}

export function isInsiteEditorLocation(location) {
  return isVisualDesignerLocation(location) && getVisualDesignerMode(location) === 'insiteEditor';
}

export function initApiForVisualDesigner(location, api) {
  if (!isVisualDesignerLocation(location))
    return;

  api.headers.add(visualDesignerHeader, true);
  isInsiteEditorLocation(location) && api.headers.add(insiteEditorHeader, true);
}

export function updateStructure(row, columnsStructure) {
  return row.columns.map((col, index) => {
    const colspan = createColspan(columnsStructure[index]);
    return {
      ...col,
      colspan,
      weight: columnsStructure[index],
    };
  });
}

export function getColumnsStructure(columnsLength) {
  const maxUnits = 12;
  const columnsStructure = [];
  const unitsPerCol = Math.floor(maxUnits / columnsLength);

  for (let i = 0; i < columnsLength; i++) {
    columnsStructure.push(unitsPerCol);
  }

  const totalUnits = unitsPerCol * columnsLength;
  if (totalUnits !== maxUnits) {
    let freeUnits = maxUnits - totalUnits;
    do {
      for (let i = 0; i < columnsLength; i++) {
        if (!freeUnits)
          break;

        columnsStructure[i]++;
        freeUnits--;
      }
    }
    while (freeUnits);
  }

  return columnsStructure;
}

function createColspan(units) {
  return {
    sm: units,
    md: units,
    lg: units,
  };
}

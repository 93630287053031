type EmailEditorSettings = {
  maxLength: number;
  unique: boolean;
};

type TextBoxEditorSettings = {
  maxLength: number;
};

type DropdownEditorOption = {
  name: string;
  value: any;
};

type DropdownEditorSettings = {
  options: DropdownEditorOption[];
};

type Range = {
  min: number | null;
  max: number | null;
};

type NumberEditorSettings = {
  range: Range | null;
};

type EnumEditorOption = {
  name: string;
  value: number;
};

type EnumEditorSettings = {
  options: EnumEditorOption[];
};

type CountryOption = {
  id: string;
  name: string;
};

type CountryEditorSettings = {
  countries: CountryOption[];
};

type StateOption = {
  code: string;
  countryId: string;
  name: string;
};

type StateEditorSettings = {
  allowCustomState: boolean;
  maxLength: number;
  states: StateOption[];
};

type PatternMaskEntry = {
  mask: string;
  pattern: string;
};

type CountryPatternsEntry = {
  countryId: string | null;
  patterns: PatternMaskEntry[];
};

type CountryDependentTextBoxEditorSettings = {
  maxLength: number;
  perCountryPatterns: CountryPatternsEntry[];
};

type DateDropdownEditorSettings = {
  minAge: number;
};

type StreetNoEditorSettings = {
  availableCountryIds: string[];
  maxLength: number;
};

type DefaultTemplateFieldEditorSettings = {
  required: boolean;
};

type TerritorialUnitFieldEditorSettings = {
  required: boolean;
  parentUnitType: string;
  type: string;
};

type WithRequired<T> = T & { required: boolean };

type StringField = {
  editor: 'STRING';
  settings: WithRequired<TextBoxEditorSettings>;
};

type StringDropdownField = {
  editor: 'STRING_DROPDOWN';
  settings: WithRequired<DropdownEditorSettings>;
};

type BooleanCheckboxField = {
  editor: 'BOOLEAN_CHECK_BOX';
  settings: DefaultTemplateFieldEditorSettings;
};

type BooleanRadioField = {
  editor: 'BOOLEAN_RADIO';
  settings: DefaultTemplateFieldEditorSettings;
};

type BooleanDropdownField = {
  editor: 'BOOLEAN_DROPDOWN';
  settings: DefaultTemplateFieldEditorSettings;
};

type EmailField = {
  editor: 'EMAIL';
  settings: WithRequired<EmailEditorSettings>;
};

type ShopAccountEmailField = {
  editor: 'SHOP_ACCOUNT_EMAIL';
  settings: WithRequired<EmailEditorSettings>;
};

type AddressNameField = {
  editor: 'ADDRESS_NAME';
  settings: WithRequired<TextBoxEditorSettings>;
};

type CountryField = {
  editor: 'COUNTRY';
  settings: WithRequired<CountryEditorSettings>;
};

type StateField = {
  editor: 'STATE';
  settings: WithRequired<StateEditorSettings>;
};

type ZipCodeField = {
  editor: 'ZIP_CODE';
  settings: WithRequired<CountryDependentTextBoxEditorSettings>;
};

type DatePickerField = {
  editor: 'DATE_DATE_PICKER';
  settings: DefaultTemplateFieldEditorSettings;
};

type DateDropdownsField = {
  editor: 'DATE_DROPDOWNS';
  settings: WithRequired<DateDropdownEditorSettings>;
};

type IntegerField = {
  editor: 'INTEGER';
  settings: WithRequired<NumberEditorSettings>;
};

type IntegerDropdownField = {
  editor: 'INTEGER_DROPDOWN';
  settings: WithRequired<DropdownEditorSettings>;
};

type EnumDropdownField = {
  editor: 'ENUM_DROPDOWN';
  settings: WithRequired<EnumEditorSettings>;
};

type BigIntegerField = {
  editor: 'BIG_INTEGER';
  settings: WithRequired<NumberEditorSettings>;
};

type BigIntegerDropdownField = {
  editor: 'BIG_INTEGER_DROPDOWN';
  settings: DropdownEditorSettings;
};

type DecimalField = {
  editor: 'DECIMAL';
  settings: WithRequired<NumberEditorSettings>;
};

type DecimalDropdownField = {
  editor: 'DECIMAL_DROPDOWN';
  settings: DropdownEditorSettings;
};

type TaxCodeField = {
  editor: 'TAX_CODE';
  settings: WithRequired<CountryDependentTextBoxEditorSettings>;
};

type PhoneNumberField = {
  editor: 'PHONE_NUMBER';
  settings: WithRequired<TextBoxEditorSettings>;
};

type StreetNoField = {
  editor: 'STREET_NO';
  settings: WithRequired<StreetNoEditorSettings>;
};

type TerritorialUnitField = {
  editor: 'TERRITORIAL_UNIT';
  settings: WithRequired<TerritorialUnitFieldEditorSettings>;
};

type EditorField =
  | StringField
  | StringDropdownField
  | BooleanCheckboxField
  | BooleanRadioField
  | BooleanDropdownField
  | EmailField
  | ShopAccountEmailField
  | AddressNameField
  | CountryField
  | StateField
  | ZipCodeField
  | DatePickerField
  | DateDropdownsField
  | IntegerField
  | IntegerDropdownField
  | EnumDropdownField
  | BigIntegerField
  | BigIntegerDropdownField
  | DecimalField
  | DecimalDropdownField
  | TaxCodeField
  | PhoneNumberField
  | StreetNoField
  | TerritorialUnitField;

type EditableTemplateField = EditorField & {
  type: 'EditableTemplateField';
  defaultValue: any;
  name: string;
  title: string;
  value: any;
};

type CountryReadOnlyEditorSettings = {
  countries: CountryOption[];
};

type StreetNoReadOnlyEditorSettings = {
  availableCountryIds: string[];
};

type ReadOnlyEditorFieldWithoutSettings = {
  displayType:
  | 'STRING_READ_ONLY'
  | 'BOOLEAN_READ_ONLY'
  | 'EMAIL_READ_ONLY'
  | 'SHOP_ACCOUNT_EMAIL_READ_ONLY'
  | 'ADDRESS_NAME_READ_ONLY'
  | 'ZIP_CODE_READ_ONLY'
  | 'DATE_READ_ONLY'
  | 'INTEGER_READ_ONLY'
  | 'ENUM_READ_ONLY'
  | 'BIG_INTEGER_READ_ONLY'
  | 'DECIMAL_READ_ONLY'
  | 'TAX_CODE_READ_ONLY'
  | 'PHONE_NUMBER_READ_ONLY'
  | 'STATE_READ_ONLY';
  settings: null;
};

type CountryReadOnlyField = {
  displayType: 'COUNTRY_READ_ONLY';
  settings: CountryReadOnlyEditorSettings;
};

type StreetNoReadOnlyField = {
  displayType: 'STREET_NO_READ_ONLY';
  settings: StreetNoReadOnlyEditorSettings;
};

type ReadOnlyEditorField =
  | ReadOnlyEditorFieldWithoutSettings
  | CountryReadOnlyField
  | StreetNoReadOnlyField;

type ReadOnlyTemplateField = ReadOnlyEditorField & {
  type: 'ReadOnlyTemplateField';
  displayValue: string | null;
  name: string;
  title: string;
  value: any;
};

export const enum TerritorialUnitType {
  Country = 'COUNTRY',
  State = 'STATE',
  Province = 'PROVINCE',
  Canton = 'CANTON',
  District = 'DISTRICT',
}

export type EntityTemplateField = EditableTemplateField | ReadOnlyTemplateField;

export type TerritorialUnit = {
  id: string;
  name: string;
  parentId: string;
  type: TerritorialUnitType;
};

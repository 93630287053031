import styles from './MediaGallery.module.scss';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { ProductMediaType } from 'behavior/pages/product';
import { useSimpleTexts } from 'components/sanaText';

const GalleryControls = ({ item, openLargeGallery }) => {
  const visible = item.type === ProductMediaType.Video || (item.type === ProductMediaType.Image && !!item.large);

  const {
    texts: [text],
    loaded,
  } = useSimpleTexts([item.type === ProductMediaType.Video ? 'VideoZoom_Message' : 'ImageZoom_Message']);

  return (
    <div className={styles.previewControls} hidden={!visible}>
      {loaded
        ? <button className={styles.link} onClick={openLargeGallery}>{text}</button>
        : <Placeholder className={styles.linkPlaceholder} />
      }
    </div>
  );
};

GalleryControls.propTypes = {
  item: PropTypes.shape({
    large: PropTypes.string,
    videoData: PropTypes.object,
    type: PropTypes.oneOf([ProductMediaType.Video, ProductMediaType.Image]),
  }),
  openLargeGallery: PropTypes.func.isRequired,
};

export default GalleryControls;
import styles from './Gallery.module.scss';
import { thumbnailSize } from '../thumbnails/Thumbnails.module.scss';
import { memo, useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';
import Spinner from 'components/primitives/spinner/Spinner';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import GalleryImage from './GalleryImage';
import GalleryVideo from './GalleryVideo';
import { ProductMediaType } from 'behavior/pages/product';

const Gallery = ({ index = 0, items, noImage, onChange, showLargeGallery, onPlayerChange }) => {
  const mediaRef = useRef(null);
  const fadeTimer = useRef(0);
  const activeIndex = useRef(index);
  activeIndex.current = index;

  const zoomState = useState(false);
  const [productNoImageText] = useSanaTexts(['Product_NoImage'], makeSimpleText).texts;

  const activeItem = items[activeIndex.current];

  if (!activeItem)
    activeIndex.current = 0;

  const itemProps = getItemProps(activeItem, noImage, productNoImageText);

  const goTo = useCallback(index => {
    if (index === activeIndex.current)
      return;
    const newItem = items[index];
    if (!newItem)
      return;

    const mediaElement = mediaRef.current;
    mediaElement.parentElement.classList.remove(styles.fadeIn);

    clearTimeout(fadeTimer.current);

    fadeTimer.current = setTimeout(() => {
      const newProps = getItemProps(newItem, noImage);

      if (newItem.type === ProductMediaType.Image) {
        mediaElement.src = newProps.src;
        mediaElement.alt = newProps.title;
      }

      if (itemProps.src === newProps.src || itemProps.url === newProps.url) {
        mediaElement.parentElement.classList.add(styles.fadeIn);
      }

      activeIndex.current = index;
      onChange && onChange(activeIndex.current);
    }, styles.fadeTime);
  }, [index]);

  const swipeNext = () => {
    if (!zoomState[0])
      goTo(activeIndex.current + 1);
  };

  const swipePrev = () => {
    if (!zoomState[0])
      goTo(activeIndex.current - 1);
  };

  useEffect(() => {
    goTo(index);
  }, [index]);

  const onLoad = useCallback(() => {
    mediaRef.current.parentElement.classList.add(styles.fadeIn);
  });

  const imageSlide = activeItem && activeItem.type === ProductMediaType.Video
    ? (
      <GalleryVideo
        videoData={itemProps}
        ref={mediaRef}
        onLoad={onLoad}
        onPlayerChange={onPlayerChange}
        noImage={noImage}
      />
    )
    : (
      <GalleryImage
        imgProps={itemProps}
        index={index}
        showLargeGallery={showLargeGallery}
        zoomState={zoomState}
        item={activeItem}
        onLoad={onLoad}
        ref={mediaRef}
      />
    );

  return (
    <Swipe className={styles.feature}
      allowMouseEvents
      onSwipeLeft={swipeNext}
      onSwipeRight={swipePrev}
      tolerance={thumbnailSize / 2}
    >
      <div className={styles.spinner}><Spinner /></div>
      {imageSlide}
    </Swipe>
  );
};

Gallery.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number,
  noImage: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showLargeGallery: PropTypes.func,
  onPlayerChange: PropTypes.func.isRequired,
};

export default memo(Gallery);

function getItemProps(item, noImage, productNoImageText) {
  if (!item) {
    return {
      src: noImage,
      title: productNoImageText,
      className: styles.noImage,
    };
  }

  if (item.type === ProductMediaType.Video)
    return item.videoData;

  return {
    src: item.medium || item.small,
    title: item.title,
  };
}

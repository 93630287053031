import type { Handler } from 'behavior/pages/types';
import type { RouteName } from 'routes';
import type {
  Product,
  CalculatedProduct,
  ProductImage,
  ProductVideo,
  RelatedProduct,
  RelatedProductGroup,
} from './types';
import type { AppState } from 'behavior';
import type { LoadedSettings } from 'behavior/settings';
import { createProductPageQuery } from './queries';
import { map, first, switchMap, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { RowContentElement, RowContentElementData, parseContent } from 'behavior/content';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { routesBuilder } from 'routes';
import { ProductMediaType, Presets } from './constants';
import { PageComponentNames } from '../componentNames';
import { printModeEnabled } from 'behavior/printMode';
import { parseVideoData } from 'utils/video';

const handler: Handler<ProductRouteData, ProductPage> = ({ params: { id, agreementLine: salesAgreementLineId } }, state$, { api, scope }) => id
  ? state$.pipe(
    first(state => state.settings.loaded),
    switchMap(state => {
      const options = {
        scope,
        isInsiteEditor: state.insiteEditor.initialized,
        isProductGroupingEnabled: (state.settings as LoadedSettings).product.productGrouping.isEnabled,
      };
      const query = createProductPageQuery(options);
      const isTrackingEnabled = state.analytics && state.analytics.isTrackingEnabled;
      const variables = {
        productId: id,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        detailsPreset: Presets.Details,
        loadRelatedProductsCategories: isTrackingEnabled,
        loadUom: isTrackingEnabled,
      };

      const isPrintMode = printModeEnabled(state.routing);

      return api.graphApi<ProductPageResponse>(query, variables).pipe(
        pluck('pages', 'product'),
        map(productPageData => {
          if (!productPageData)
            return null;

          const productData = productPageData.product;
          const product = {
            ...mergeWithProductFromState(productData, state),
            relatedProductGroups: parseRelatedProducts(productData.relatedProductGroups),
            media: processMedia(productData.media),
          };

          const page: ProductPage = {
            ...productPageData,
            component: PageComponentNames.Product,
            middleContent: productPageData.middleContent && parseContent(productPageData.middleContent),
            footerContent: productPageData.footerContent && parseContent(productPageData.footerContent),
            index: !isPrintMode,
            isPrintMode,
            product,
          };

          if (!!salesAgreementLineId)
            page.salesAgreement = { preSelectedLine: { id: salesAgreementLineId } };

          return { page };
        }),
      );
    }),
  )
  : of(null);

export default handler;

function mergeWithProductFromState(product: ProductData, state: AppState) {
  if (state.routing.navigatingTo?.location === state.routing.location)
    return product;

  const page = state.page as { product: Product & CalculatedProduct };
  if (page.product == null || page.product.id !== product.id)
    return product;

  return {
    ...page.product,
    ...product,
    loaded: false,
  };
}

function parseRelatedProducts(relatedProductGroups: RelatedProductGroupData[] | null): RelatedProductGroup[] | null {
  if (!relatedProductGroups)
    return null;

  for (const group of relatedProductGroups)
    for (const product of group.products)
      (product as RelatedProduct).routeData = routesBuilder.forProduct(product.id);

  return relatedProductGroups as RelatedProductGroup[];
}

function processMedia(media: ProductMediaData[] | null) {
  if (!media)
    return null;

  return media.map(mediaItem => {
    if (mediaItem.type === ProductMediaType.Video) {
      const videoData = parseVideoData(mediaItem.url);

      return {
        type: mediaItem.type,
        videoData,
      };
    }

    return mediaItem;
  }).filter((mediaItem): mediaItem is ProductMedia => {
    if (mediaItem.type === ProductMediaType.Video)
      return !!mediaItem.videoData;

    return !!mediaItem.small || !!mediaItem.medium || !!mediaItem.large;
  });
}

type ProductMediaData = ProductImage | Omit<ProductVideo, 'videoData'> & { url: string };
type ProductMedia = ProductImage | ProductVideo;

type RelatedProductGroupData = Omit<RelatedProductGroup, 'products'> & {
  products: Omit<RelatedProduct, 'routeData'>[];
};

type ProductData = Omit<Product, 'media' | 'relatedProductGroups'> & {
  media: ProductMediaData[] | null;
  relatedProductGroups: RelatedProductGroupData[] | null;
};

type ProductPageData = {
  metaTitle: string | null;
  metaDescription: string | null;
  header: string | null;
  preset: Presets;
  middleContent: RowContentElementData[] | null;
  footerContent: RowContentElementData[] | null;
  product: ProductData;
};

type ProductPageResponse = {
  pages: {
    product: ProductPageData | null;
  };
};

type ProductRouteData = {
  routeName: RouteName.ProductDetails;
  params: {
    id: string;
    agreementLine?: string;
  };
};

type ProductPage = {
  component: PageComponentNames.Product;
  metaTitle: string | null;
  metaDescription: string | null;
  header: string | null;
  preset: Presets;
  middleContent: RowContentElement[] | null;
  footerContent: RowContentElement[] | null;
  product: Product | Product & CalculatedProduct & { loaded: false };
  salesAgreement?: {
    preSelectedLine?: {
      id: string;
    };
  };
  index: boolean;
  isPrintMode: boolean;
};

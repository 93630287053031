exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Gallery_feature{box-sizing:content-box;height:320px;margin-bottom:2em;position:relative;width:100%}.Gallery_feature img{bottom:0;display:block;left:0;margin:auto;max-height:100%;max-width:100%;opacity:0;position:absolute;right:0;top:0;transition:opacity .2s}.Gallery_feature .Gallery_video-container{align-items:center;display:flex;height:100%;justify-content:center;opacity:0}.Gallery_feature .Gallery_video{flex-grow:0;flex-shrink:0;max-width:100%;width:568.8888888889px}.Gallery_feature .Gallery_spinner{left:50%;position:absolute;top:50%;transform:translate(-1em,-1em);transition:opacity .2s .2s}.Gallery_feature.Gallery_fade-in .Gallery_video-container,.Gallery_feature.Gallery_fade-in img,.ssr-markup .Gallery_feature .Gallery_video-container,.ssr-markup .Gallery_feature img{opacity:1}.Gallery_feature.Gallery_fade-in .Gallery_spinner,.ssr-markup .Gallery_feature .Gallery_spinner{transition-delay:0s}.Gallery_zoom{background-color:var(--theme-page_BackgroundColor,#fff);border:solid 2px var(--theme-borderColor,#e6e6e6);box-sizing:content-box;margin:-2px 0 0 -2px;min-height:3em;min-width:3em;opacity:0;overflow:hidden;pointer-events:none;position:fixed;transition:opacity .2s;z-index:1}.Gallery_zoom.Gallery_fade-in{opacity:1}.Gallery_zoom .Gallery_spinner{left:50%;margin-left:-1em;margin-top:-1em;top:50%}.Gallery_zoom .Gallery_spinner,.Gallery_zoom img{position:absolute;transition:opacity .2s}.Gallery_zoom img{-webkit-backface-visibility:hidden;backface-visibility:hidden;display:block;height:auto;left:0;opacity:0;top:0}.Gallery_zoom.ready .Gallery_spinner{opacity:0}.Gallery_zoom.ready img{opacity:1}.Gallery_zoom-lense{background-color:hsla(0,0%,100%,.3);border:solid 1px var(--theme-borderColor,#e6e6e6);box-sizing:border-box;height:150px;left:0;opacity:0;pointer-events:none;position:fixed;top:0;transition:opacity .2s;width:100px;z-index:1}.Gallery_zoom-lense.Gallery_fade-in{opacity:1}.Gallery_no-image{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"fadeTime": "200",
	"feature": "Gallery_feature",
	"video-container": "Gallery_video-container",
	"videoContainer": "Gallery_video-container",
	"video": "Gallery_video",
	"spinner": "Gallery_spinner",
	"fade-in": "Gallery_fade-in",
	"fadeIn": "Gallery_fade-in",
	"zoom": "Gallery_zoom",
	"zoom-lense": "Gallery_zoom-lense",
	"zoomLense": "Gallery_zoom-lense",
	"no-image": "Gallery_no-image",
	"noImage": "Gallery_no-image"
};
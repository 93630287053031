import { TERRITORIAL_UNITS_RECEIVED, TERRITORIAL_UNITS_REQUESTED, EntityTemplateFieldsAction } from './actions';
import { TerritorialUnit } from './types';

type State = {
  territorialUnitsLoading: boolean | null;
  territorialUnits: TerritorialUnit[];
};

export default function reducer(state: State, action: EntityTemplateFieldsAction) {
  switch (action.type) {
    case TERRITORIAL_UNITS_RECEIVED:
      return {
        ...state,
        territorialUnitsLoading: false,
        territorialUnits: [...getNewTerritorialUnits(state.territorialUnits ?? [], action.payload)],
      };
    case TERRITORIAL_UNITS_REQUESTED:
      return { ...state, territorialUnitsLoading: true };
    default:
      return state;
  }
}

function getNewTerritorialUnits(oldUnits: TerritorialUnit[], newUnits: TerritorialUnit[]) {
  const unitsToAdd = newUnits.filter(unit => !oldUnits.some(oldUnit => oldUnit.id === unit.id && oldUnit.parentId === unit.parentId && oldUnit.type === unit.type));
  return [...oldUnits, ...unitsToAdd];
}